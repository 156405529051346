import React from "react"

import { Facebook, Twitter, Reddit, Whatsapp, Mail, Xing } from 'react-social-sharing';
import { FacebookButton } from 'react-social';

class SocialSharing extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        const { location, title, siteUrl } = this.props;

        var _link = siteUrl + location.pathname;
        return (
            <div>
                
                    <Facebook solidcircle small link={_link}/>
                    <Twitter solidcircle small link={_link}/>
                    <Reddit solidcircle small link={_link}/>
                    <Whatsapp solidcircle small message={title} link={_link}/>
                    <Mail solidcircle small subject={title} body={_link}/>
                
            </div>
        )
    }
}

export default SocialSharing