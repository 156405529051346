import Typography from "typography"
//import deYoungTheme from "typography-theme-de-young"
import moragaTheme from 'typography-theme-moraga'
//import Wordpress2016 from "typography-theme-wordpress-2016"
/*
Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)
*/
moragaTheme.baseLineHeight = 1.5;
const typography = new Typography(moragaTheme);
// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  //typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale

/*
const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.0,
  headerFontFamily: [
    "Avenir Next",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  bodyFontFamily: ["Georgia", "serif"],
})

export default typography
*/