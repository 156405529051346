import React from "react"
import { Link } from "gatsby"

import "./recentposts"
import "../styles/mini-default.min.css"
import "../styles/site.css"
//import "mini.css"

import { rhythm, scale } from "../utils/typography"
import recentposts from "./recentposts";


class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`


/*
    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
*/
    return (
      <div className="container">
      <div >
        <header clasName="row">
          <div className="col-sm-12 col-lg-12">
            <div className="col-sm-12 col-lg-1 col-lg-offset-2">
              <Link
                to={`/`}
                className="logo"
              >
              
              {title}
              </Link>
            </div>
            <div className="col-sm-12 col-lg-8 col-lg-offset-2">
              {` `}
              <Link
                to={`/read`}
                className="button"
              >
                Read
              </Link>
            
              <Link
                to={`/call`}
                className="button"
              >
                Call
              </Link>
            </div>
          </div>
        </header>
      </div>
      <div className="row"></div>
      <div className="row">
        <div className="col-sm-12 col-lg-8 col-lg-offset-2">
          <br/>
          <main>{children}</main>
        </div>
      </div>
      <div className="row col-sm-12 col-lg-8 col-lg-offset-2">
        <footer>
          © {new Date().getFullYear()} | {` `}
          {<Link to={`/about`}>About</Link>} | {` `}
          {<Link to={`/advertising-policy`}>Advertising Policy</Link>} | {` `}
          {<Link to={`/privacy-policy`}>Privacy Policy</Link>} | {` `}
          {<Link to={`/consult`}>Consult</Link>} | {` `}
        </footer>
      </div>
      </div>
    )
  }
}

export default Layout
